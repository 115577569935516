<template>
  <div class="shizi">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div class="teachersContent">
      <div class="teachersContentTitle">优秀师资团队介绍</div>
      <div class="contentBox">
        <p>
          燕京雍阳的老师都经过专业培训，
          <br />
          拥有丰富的教育经验和热爱教育的心。
          <br />
          他们不仅是杰出的教育者，还是孩子们的榜样和导师。
          <br />
          <br />

          专业背景：老师拥有教育学或相关领域的学位，经验丰富。<br />
          热情和爱心：教育团队充满热情，将孩子视为自己的家人。<br />
          创新和关怀：致力于不断创新教育方法，满足每个孩子的需求。
          <br />
          <br />
          师资团队将竭尽全力，为每个孩子提供最好的教育和关爱。
        </p>
      </div>
      <div class="TaskIntroduction">
        <div
          class="TaskIntroductionBox"
          v-for="(item, index) in teacherList"
          :key="index"
        >
          <div v-if="index % 2 == 0" class="bbc">
            <div class="BoxOneLeft">
              <img :src="item.img" alt="" />
            </div>
            <div class="BoxOneRight">
              <div class="BoxOneRightTitle">
                {{ item.title }}
              </div>
              <div class="BoxOneRightContent" v-html="item.text"></div>
            </div>
          </div>

          <div v-if="index % 2 != 0" class="bbc">
            <div class="BoxOneLeft">
              <div class="BoxOneRightTitle">
                {{ item.title }}
              </div>
              <div class="BoxOneRightContent" v-html="item.text"></div>
            </div>
            <div class="BoxOneRight">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="phoneTask">
        <div
          class="phoneTaskTop"
          v-for="(item, index) in teacherList"
          :key="index"
        >
          <div class="phoneTaskTopImgOrTi" v-if="index == 0">
            <div class="phoneTaskTopImg">
              <img :src="item.img" alt="" />
            </div>
            <div class="phoneTaskTopTitle">
              {{ item.title }}
            </div>
          </div>
          <div
            class="phoneTaskTopContent"
            v-if="index == 0"
            v-html="item.text"
          ></div>
        </div>

        <div
          class="phoneTaskContent"
          v-for="(item, index) in teacherList"
          :key="index"
        >
          <div class="phoneTaskBottom" v-if="index > 0 && index % 2 != 0">
            <div class="phoneTaskBottomText" v-html="item.text"></div>
            <div class="phoneTaskBottomRight">
              <div class="phoneTaskBottomRightImg">
                <img :src="item.img" alt="" />
              </div>
              <div class="phoneTaskBottomRightTitle">
                {{ item.title }}
              </div>
            </div>
          </div>

          <div class="phoneTaskBottom" v-if="index > 0 && index % 2 == 0">
            <div class="phoneTaskBottomRight mr25">
              <div class="phoneTaskBottomRightImg">
                <img :src="item.img" alt="" />
              </div>
              <div class="phoneTaskBottomRightTitle">
                {{ item.title }}
              </div>
            </div>
            <div class="phoneTaskBottomText w60" v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>

    <footerBtm></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "shizi",
  components: { headerNav, footerBtm },
  data() {
    return {
      teacherList: [],
      bannerList: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.api.allPage({ name: '师资团队' }).then((res) => {})
    this.api.banner({ remark1: 8 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
    this.teacherNewList();
  },
  methods: {
    teacherNewList() {
      this.api.teacherNewList().then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          res.data[i].img = JSON.parse(res.data[i].img);
        }
        this.teacherList = res.data;
      });
    },
  },
};
</script>
<style scoped>
.el-carousel,
.el-carousel__item,
.el-carousel img {
  width: 100%;
}
.teachersContent {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 150px;
}
.teachersContentTitle {
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/teacherYeTitle.png");
  text-align: center;
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #25ae85;
  margin-top: 36px;
}
.TaskIntroduction {
  margin-top: 134px;
  display: block;
}
.phoneTask {
  display: none;
}
.TaskIntroductionBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 200px;
}
.bbc {
  display: flex;
}

.TaskIntroductionBox:nth-of-type(odd) .BoxOneLeft {
  width: 366px;
  height: 466px;
}
.TaskIntroductionBox:nth-of-type(odd) .BoxOneLeft img {
  width: 100%;
  height: 100%;
}
.TaskIntroductionBox:nth-of-type(odd) .BoxOneRight {
  width: 860px;
  height: 534px;
  padding: 0 20px 0 80px;
  box-sizing: border-box;
}
.BoxOneRightTitle {
  font-size: 36px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #25ae85;
}
.BoxOneRightContent {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  margin-top: 30px;
}

.TaskIntroductionBox:nth-of-type(even) .BoxOneRight {
  width: 366px;
  height: 466px;
}
.TaskIntroductionBox:nth-of-type(even) .BoxOneRight img {
  width: 100%;
  height: 100%;
}
.TaskIntroductionBox:nth-of-type(even) .BoxOneLeft {
  width: 860px;
  height: 534px;
  padding: 0 80px 0 20px;
  box-sizing: border-box;
}
.contentBox {
  width: 1280px;
  height: 380px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/shiMingKuang.png");
  background-size: 100% 100%;
  text-align: center;
  /* background: red; */
  margin: 0 auto;
  margin-top: 50px;
}
.contentBox p {
  width: 90%;
  padding-top: 50px;
  margin: 0 auto;
  text-align: center;
  font-size: 25px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #975b00;
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .contentBox {
    width: 100%;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
  .teachersContentTitle{
    font-size: 60px;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
   div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  .teachersContent{
    width: 910px;
  }
  .teachersContentTitle{
    width: 910px;
    height: 100px;
    margin-top: 57px;
    font-size: 43px;
    text-align: center;
    line-height: 140px;
  }
  .contentBox{
    margin-top: 48px;
    height: 300px;
  }
  .contentBox p{
    padding-top: 30px;
    font-size: 20px;
  }
  .TaskIntroduction{
    margin-top: 48px;
  }
  .bbc{
    align-items: center;
  }
  .TaskIntroductionBox{
    margin-bottom: 142px;
  }
  .TaskIntroductionBox:nth-of-type(odd) .BoxOneRight,.TaskIntroductionBox:nth-of-type(even) .BoxOneLeft{
    width: 612px;
    height: 380px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .TaskIntroductionBox:nth-of-type(odd) .BoxOneLeft,.TaskIntroductionBox:nth-of-type(even) .BoxOneRight{
    width: 256px;
    height: 332px;
  }
  .BoxOneRightTitle{
    font-size: 26px;
  }
  .BoxOneRightContent{
    margin-top: 24px;
    font-size: 17px;
  }
  div /deep/ .BoxOneRightContent ul li span{
    font-size: 17px !important;
  }
  .TaskIntroductionBox:nth-of-type(3) .BoxOneLeft{
    width: 256px;
    height: 285px;
  }
  .TaskIntroductionBox:nth-of-type(2) .BoxOneRight{
    width: 256px;
    height: 305px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
   div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  .teachersContent{
    width: 800px;
    margin-bottom: 133px;
  }
  .teachersContentTitle{
    width: 800px;
    height: 93px;
    margin-top: 53px;
    font-size: 40px;
    text-align: center;
    line-height: 140px;
    background-size: 100% 100%;
  }
  .contentBox{
    width: 800px;
    margin-top: 48px;
    height: 300px;
  }
  .contentBox p{
    padding-top: 30px;
    font-size: 20px;
  }
  .TaskIntroduction{
    margin-top: 48px;
  }
  .bbc{
    align-items: center;
  }
  .TaskIntroductionBox{
    margin-bottom: 133px;
  }
  .TaskIntroductionBox:nth-of-type(odd) .BoxOneRight,.TaskIntroductionBox:nth-of-type(even) .BoxOneLeft{
    width: 573px;
    height: 356px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .TaskIntroductionBox:nth-of-type(odd) .BoxOneLeft,.TaskIntroductionBox:nth-of-type(even) .BoxOneRight{
    width: 240px;
    height: 311px;
  }
  .BoxOneRightTitle{
    font-size: 24px;
  }
  .BoxOneRightContent{
    margin-top: 24px;
    font-size: 16px;
  }
  .TaskIntroductionBox:nth-of-type(3) .BoxOneLeft{
    width: 240px;
    height: 285px;
  }
  .TaskIntroductionBox:nth-of-type(2) .BoxOneRight{
    width: 240px;
    height: 267px;
  }
}

@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .teachersContent {
    width: 100%;
    margin-bottom: 30px;
  }
  .titleBj {
    width: 100%;
    height: 47px;
  }
  .teachersContentTitle {
    width: 100%;
    height: 47px;
    font-size: 20px;
    background-size: 100% 100%;
    padding-top: 20px;
    box-sizing: border-box;
  }
  .TaskIntroduction {
    margin-top: 43px;
    display: none;
  }
  .phoneTask {
    display: block;

    box-sizing: border-box;
    margin-top: 40px;
  }
  .phoneTaskTop {
    background-color: #fffde8;
    padding: 20px 16px;
    box-sizing: border-box;
  }
  .phoneTaskTopImgOrTi {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .phoneTaskTopImg {
    width: 120px;
    height: 150px;
  }
  .phoneTaskTopImg img {
    width: 100%;
    height: 100%;
  }
  .phoneTaskTopTitle {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
  }
  .phoneTaskTopContent {
    width: 90%;
    font-size: 12px !important;
    color: #975b00;
    margin: 0 auto;
    margin-top: 20px;
  }
  div /deep/ .phoneTaskTopContent ul li span {
    font-size: 12px !important;
  }
  
  .phoneTaskBottom {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: #fffde8;
    padding: 20px 16px;
    box-sizing: border-box;

  }
  .phoneTaskBottomText {
    width: 55%;
    color: #975b00;
    margin: 0 auto;
    margin-top: 20px;
  }
  div /deep/ .phoneTaskBottomText ul li {
    font-size: 12px !important;
  }
  .phoneTaskBottomRightImg {
    width: 124px;
    height: 138px;
  }
  .phoneTaskBottomRightImg img {
    width: 100%;
    height: 100%;
  }
  .phoneTaskBottomRightTitle {
    font-size: 12px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    line-height: 13px;
    text-align: center;
    margin-top: 20px;
  }
  .mr25 {
    margin-right: 25px;
  }
  .w60 {
    width: auto;
  }
  .phoneTaskTop:nth-of-type(2) {
    display: none;
  }
  .phoneTaskTop:nth-of-type(3) {
    display: none;
  }

  .teachersContent {
    width: 375px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .teachersContentTitle {
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/teacherYeTitle.png");
    text-align: center;
    font-size: 30px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    margin-top: 36px;
  }

  .contentBox {
    width: 95%;
    height: 200px;
    /* background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/shiMingKuang.png"); */
    background: none;
    text-align: center;
    /* background: red; */
    margin: 0 auto;
    margin-top: 50px;
    border-bottom: 3px dotted #25ae85;
    border-top: 3px dotted #25ae85;
  }
  .contentBox p {
    width: 98%;
    padding-top: 20px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #975b00;
  }
}
</style>
